<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'view-rejection-orders',
};
</script>
